import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService, CreateProfileService, StaticModuleService } from './services/auth.service';

const routes: Routes = [
    {
        path: 'register', // Don't change url because app has a deep linking
        loadChildren: () => import('./create-profile/create-profile.module').then(m => m.CreateProfileModule),
        canLoad: [CreateProfileService],
        data: {
            title: 'Profile questionnaire | Jodi365.com',
            description : 'Join the best matchmaking site for educated singles. Find the right partner, at your own pace. Get matched for free.'
        }
    },
    {
        path: 'user', // Don't change url because app has a deep linking
        loadChildren: () => import('./post-login/post-login.module').then(m => m.PostLoginModule),
        canLoad: [AuthService]
    },
    {
        path: '',
        loadChildren: () => import('./static/static.module').then(m => m.StaticModule),
        canLoad: [StaticModuleService]
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy', anchorScrolling: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
