import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
// tslint:disable:max-line-length
@Injectable({
  providedIn: 'root'
})
export class ApiService implements HttpInterceptor {

  public static ServerFlag = 0;
  userAccessToken: string;
  constructor(private http: HttpClient, private localStorageService: LocalStorageService, private router: Router) { }

  private static geturl(action: string) {
    return environment.baseUrl + action;
  }

  getToken() {
    if (this.localStorageService.getFromLocal('user')) {
      return this.localStorageService.getFromLocal('user').access_token;
    } else {
      return '';
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        authorization: this.getToken()
      }
    });

    return next.handle(request);
  }

  // Email Exist
  doesemailexist = function (params) {
    return this.http.post(ApiService.geturl('doesemailexist'), params);
  };

  // signup
  signup = function (params) {
    return this.http.post(ApiService.geturl('signup'), params);
  };

  // login
  login = function (params) {
    return this.http.post(ApiService.geturl('login'), params);
  };

  // forgot password
  forgotpassword = function (params) {
    return this.http.post(ApiService.geturl('forgot'), params, { responseType: 'text' });
  };
  //  getemailid

  getEmail = function (params) {
    return this.http.get(ApiService.geturl('getemailid?key=') + params.key + '&userid=' + params.userid, { responseType: 'text' });
  };

  //  unsubscribe
  unsubscribeEmail = function (params) {
    return this.http.post(ApiService.geturl('unsubscribeemail'), params, { responseType: 'text' });
  };

  // resetpassword
  resetpassword = function (params) {
    return this.http.post(ApiService.geturl('resetpassword'), params);
  };

  // user get own profile
  getownprofile = function () {
    return this.http.get(ApiService.geturl('getownprofile'));
  };

  // sectionone create profile
  sectionone = function (params) {
    return this.http.post(ApiService.geturl('sectionone'), params);
  };

  // searching location
  searchforlocation = function (params) {
    return this.http.get(ApiService.geturl('searchforlocation?searchfragment=') + params.searchfragment + '&isrestrictedtocities=' + params.isrestrictedtocities + '&sequenceno=' + params.sequenceno + '&countrycode=' + params.countrycode);
  };

  // sectionone create profile
  sectiontwo = function (params) {
    return this.http.post(ApiService.geturl('sectiontwo'), params);
  };

  // sectionone create profile
  sectionthree = function (params) {
    return this.http.post(ApiService.geturl('sectionthree'), params);
  };

  // sectionfour create profile
  sectionfour = function (params) {
    return this.http.post(ApiService.geturl('sectionfour'), params);
  };
  // sectionfive create profile
  sectionfive = function (params) {
    return this.http.post(ApiService.geturl('sectionfive'), params);
  };
  // sectionsix create profile
  sectionsix = function (params) {
    return this.http.post(ApiService.geturl('sectionsix'), params);
  };

  // sectionfour create profile
  searchforuniversity = function (params) {
    return this.http.get(ApiService.geturl('searchforuniversity?searchfragment=') + params.searchfragment + '&sequenceno=' + params.sequenceno);
  };

  // getuploadimagekey addimage
  getuploadimagekey = function (params) {
    return this.http.get(ApiService.geturl('getuploadimagekey?number=') + params.number);
  };

  // getuploadimagekey addimage
  deleteimage = function (params) {
    return this.http.delete(ApiService.geturl('deleteimage?number=') + params.number);
  };

  // addimage
  addimage = function (params) {
    return this.http.post(ApiService.geturl('addimage'), params);
  };
  // editimage
  editimage = function (params) {
    return this.http.post(ApiService.geturl('editimage'), params);
  };
  // updatePhotoPrivacy
  updatePhotoPrivacy = function (params) {
    return this.http.post(ApiService.geturl('updatePhotoPrivacy'), params);
  };

  // getuploadimagekey addimage
  getpreferencesbysection = function () {
    return this.http.get(ApiService.geturl('getpreferencesbysection?section=2'));
  };
  // setstrictpreferneces
  setstrictpreferneces = function (params) {
    return this.http.post(ApiService.geturl('setstrictpreferneces'), params);
  };
  // SetnotstrictPreferences
  SetnotstrictPreferences = function (params) {
    return this.http.post(ApiService.geturl('SetnotstrictPreferences'), params);
  };
  // paidStrictPreferences
  paidStrictPreferences = function (params) {
    return this.http.post(ApiService.geturl('paidStrictPreferences'), params);
  };

  // This api will return the status of email and phone verification
  getStatus = function () {
    return this.http.get(ApiService.geturl('getstatus'));
  };

  // this API to get the OTP for verify phone number
  sendPhoneVerification = function (params) {
    return this.http.post(ApiService.geturl('sendphoneverification'), params, { responseType: 'text' });
  };
  // setFamilyInformation
  setFamilyInformation = function (params) {
    return this.http.post(ApiService.geturl('setFamilyInformation'), params);
  };

  // this API to verify phone number through OTP
  verifyPhone = function (params) {
    return this.http.post(ApiService.geturl('verifyphone'), params);
  };
  // this API to verify verifyemail
  verifyemail = function (params) {
    return this.http.post(ApiService.geturl('verifyemail'), params);
  };
  // this API to resendEmailverificationlink
  resendEmailverificationlink = function () {
    return this.http.post(ApiService.geturl('resendEmailverificationlink'));
  };

  // setAboutYou of summary
  setAboutYou = function (params) {
    return this.http.post(ApiService.geturl('setAboutYou'), params);
  };

  // getPreferenceLocation addimage
  getPreferenceLocation = function (params) {
    if (params) {
      return this.http.get(ApiService.geturl('getPreferenceLocation?locationName=') + params.locationName + '&isCountry=' + params.isCountry);
    } else {
      return this.http.get(ApiService.geturl('getPreferenceLocation'));
    }
  };

  // getbrowsematcheduserv1
  getbrowsematcheduserv1 = function (params) {
    return this.http.get(ApiService.geturl('getbrowsematcheduserv1?skip=') + params.skip + '&limit=' + params.limit + '&sortby=' + params.sortby);
  };
  // getothersprofile
  getothersprofile = function (params) {
    return this.http.get(ApiService.geturl('getOtherUserProfile?otherids=[') + params.otherids + ']');
  };
  // find hash tags for myself in summary
  findHashTags = function (params) {
    return this.http.get(ApiService.geturl('findHashTags?searchfragment=' + params.searchfragment));
  };
  // Get Interests received/sent
  getInterestUser = function (params) {
    return this.http.get(ApiService.geturl('getInterestUser?sent=') + params.sent + '&unfiltered=' + params.unfiltered + '&skip=' + params.skip);
  };
  // phone exists
  doesphoneexist = function (params) {
    return this.http.post(ApiService.geturl('doesphoneexist'), params);
  };
  // save indian phone number
  savenumber = function (params) {
    return this.http.post(ApiService.geturl('savenumber'), params);
  };
  // set privacy stages
  setprivacy = function (params) {
    return this.http.post(ApiService.geturl('setprivacy'), params);
  };
  // Get request received/sent
  getRequests = function (params) {
    return this.http.get(ApiService.geturl('getRequests?sent=') + params.sent + '&unfiltered=' + params.unfiltered + '&skip=' + params.skip);
  };

  // Get Mutual Interest received/sent
  getMutualInterest = function (params) {
    if (params.sortBy.length > 0) {
      return this.http.get(ApiService.geturl('getMutualInterest?skip=') + params.skip + '&sortBy=' + params.sortBy);
    } else {
      return this.http.get(ApiService.geturl('getMutualInterest?skip=') + params.skip);
    }
  };
  // Get Declines Interest received/sent
  getDeclines = function (params) {
    if (params.sortBy.length > 0) {
      return this.http.get(ApiService.geturl('getDeclines?skip=') + params.skip + '&sortBy=' + params.sortBy);
    } else {
      return this.http.get(ApiService.geturl('getDeclines?skip=') + params.skip);
    }
  };

  // Get JodiAlert
  getJodiNotifications = function (params) {
    return this.http.get(ApiService.geturl('getJodiNotifications?skip=') + params.skip + '&limit=' + params.limit);
  };

  // Get getinboxuser
  getinboxuser = function (params) {
    return this.http.get(ApiService.geturl('getinboxuser?skip=') + params.skip + '&limit=' + params.limit);
  };
  // Get sendInterest
  sendInterest = function (params) {
    return this.http.post(ApiService.geturl('sendInterest'), params);
  };
  // Get declineInterest
  declineInterest = function (params) {
    return this.http.post(ApiService.geturl('declineInterest'), params);
  };
  // Get saveProspect
  saveProspect = function (params) {
    return this.http.post(ApiService.geturl('saveProspect'), params);
  };
  // Get markProfileAsViewed
  markProfileAsViewed = function (params) {
    return this.http.post(ApiService.geturl('markProfileAsViewed'), params);
  };
  // Get ignoreProspect
  ignoreProspect = function (params) {
    return this.http.post(ApiService.geturl('ignoreProspect'), params);
  };
  // Get getInboxData
  getInboxData = function (params) {
    return this.http.get(ApiService.geturl('getInboxData?skip=') + params.skip + '&limit=' + params.limit + '&type=' + params.type);
  };

   // Get getRecentlyActive
   getrecentlyactiveuser = function (params) {
    return this.http.get(ApiService.geturl('getrecentlyactiveuser?skip=') + params.skip + '&limit=' + params.limit);
  };

  // Get getExploreData
  getExploreData = function (params) {
    return this.http.get(ApiService.geturl('getExploreData?skip=') + params.skip + '&relType=' + params.relType + '&type=' + params.type);
  };
  // getChatData
  getChatData = function (params) {
    return this.http.get(ApiService.geturl('getChatData?skip=') + params.skip + '&userid=' + params.userid);
  };
  // getOtherUserLimitedData
  getOtherUserLimitedData = function (params) {
    return this.http.get(ApiService.geturl('getOtherUserLimitedData?otherids=[') + params.otherids + ']');
  };
  // getUnreadChatData
  getUnreadChatData = function (params) {
    return this.http.get(ApiService.geturl('getUnreadChatData?userid=') + params.userid + '&skip=' + params.skip);
  };
  // API to mark the profile as complete
  skipBulkShortlist = function () {
    return this.http.post(ApiService.geturl('skipBulkShortlist'));
  };
  // sendChatMessage
  sendChatMessage = function (params) {
    return this.http.post(ApiService.geturl('sendChatMessage'), params);
  };
  // Api to generate matches
  profilecompleted = function () {
    return this.http.post(ApiService.geturl('profilecompleted'));
  };
  // Api to getAllNotificationUpdate
  getAllNotificationUpdate = function () {
    return this.http.get(ApiService.geturl('getAllNotificationUpdate'));
  };
  // to get user phone number
  getPhonenumber = function (params) {
    return this.http.get(ApiService.geturl('getPhonenumber?otherid=') + params.otherid);
  };
  // api to request summary
  requestsummary = function (params) {
    return this.http.post(ApiService.geturl('requestsummary'), params);
  };
  // api to get razor pay link
  saveorderv2 = function (params) {
    return this.http.post(ApiService.geturl('saveorderv2'), params);
  };
  // api to capture payment
  capturev2 = function (params) {
    return this.http.post(ApiService.geturl('capturev2'), params);
  };
  // api to contactUs
  contactUs = function (params) {
    return this.http.post(ApiService.geturl('contactUs'), params);
  };

  // api to blockUser
  blockuser = function (params) {
    return this.http.post(ApiService.geturl('blockuser'), params);
  };

  //api to reportUser
  reportuser = function (params) {
    return this.http.post(ApiService.geturl('reportuser'), params);
  };

  // api to matchPassword
  matchPassword = function (params) {
    return this.http.post(ApiService.geturl('matchPassword'), params);
  };
  // api to profilehibernate
  profilehibernate = function () {
    return this.http.post(ApiService.geturl('profilehibernate'));
  };
  // api to undohibernate
  undohibernate = function () {
    return this.http.post(ApiService.geturl('undohibernate'));
  };
  // api to profiledeleterequest
  profiledeleterequest = function (params) {
    return this.http.post(ApiService.geturl('profiledeleterequest'), params);
  };
  // api to changepassword
  changepassword = function (params) {
    return this.http.post(ApiService.geturl('changepassword'), params);
  };

  // api to get rate card for secure dial
  ratecard = function (params) {
    return this.http.get(ApiService.geturl('ratecard?otherid=') + params.otherid);
  };

  // api to call secure dial
  securedial = function (params) {
    return this.http.post(ApiService.geturl('securedial'), params);
  };

  // api to save surveyQuestion
  surveyquestionnaire = function (params) {
    return this.http.post(ApiService.geturl('surveyquestionnaire'), params);
  };

  // api to uploadphotoid
  uploadphotoid = (params) => {
    return this.http.post(ApiService.geturl('uploadphotoid'), params);
  }

  // getting key for uploading photo id
  getphotoidkey = function (params) {
    return this.http.get(ApiService.geturl('getphotoidkey?ispdf=') + params.ispdf + '&type=' + params.type);
  };

  // getScreeningSeq = function () {
  //   return this.http.get(ApiService.geturl('getScreeningSeq'));
  // };

  // get Screening Sequence number of the user
  getScreeningSeq = () => {
    return this.http.get(ApiService.geturl('getScreeningSeq'));
  }

  // Update Profile Impression
  UpdateProfileImpression = (params) => {
    return this.http.post(ApiService.geturl('UpdateProfileImpression'), params);
  }

  // api when user is goin to plan and packs
  clickcaptur = (params) => {
    return this.http.post(ApiService.geturl('clickcapture'), params);
  }

  // api call to assisted signup
  assistedsignup = (params) => {
    return this.http.post(ApiService.geturl('assistedsignup'), params);
  }

  // api to set password
  setpassword = (params) => {
    return this.http.post(ApiService.geturl('setpassword'), params);
  }

  // api call to personal signup
  personaltouchsignup = (params) => {
    return this.http.post(ApiService.geturl('personaltouchsignup'), params);
  }

  // api to set unique video id given by ziggeo recorder for selfie verification
  setvideoverificationid = (params) => {
    return this.http.post(ApiService.geturl('setvideoverificationid'), params);
  }

  // api to delete existing video
  deletevideo = function() {
    return this.http.post(ApiService.geturl('deletevideo'));
  }

  // api to update question number
  updatequestionnumber = (params) => {
    return this.http.post(ApiService.geturl('updatequestionnumber'), params)
  }

  // api to
  addvideo = function() {
    return this.http.post(ApiService.geturl('addvideo'));
  }

  // api for rating by user in profile screening
  setnpsrating = function(params) {
    return this.http.post(ApiService.geturl('setnpsrating'), params);
  }

  // api for notification settings
  getnotificationsettings = function() {
    return this.http.get(ApiService.geturl('getnotificationsettings'));
  }

  setnotificationsettings = function(params) {
    return this.http.post(ApiService.geturl('setnotificationsettings'), params);
  }

  // api for forgot password
  webforgotPassword = function(params) {
      return this.http.post(ApiService.geturl('webforgot'), params);
  }

  // api for broken image
  brokenImageReport = function() {
    return this.http.post(ApiService.geturl('brokenimagereport'));
  }
}
