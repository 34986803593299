import { Injectable } from '@angular/core';
import { Subject, Observable, of, merge, fromEvent } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from './local-storage.service';
import { ApiService } from './api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { mapTo } from 'rxjs/operators';
import { identify, Identify, setUserId, reset, track } from '@amplitude/analytics-browser';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  // height
  heightData = [
    {
      id: 137,
      value: '4ft 6in – 137cm or less'
    },
    {
      id: 138,
      value: '4ft 6.5in – 138cm'
    },
    {
      id: 140,
      value: '4ft 7in – 140cm'
    },
    {
      id: 141,
      value: '4ft 7.5in – 141cm'
    },
    {
      id: 142,
      value: '4ft 8in – 142cm'
    },
    {
      id: 144,
      value: '4ft 8.5in – 144cm'
    },
    {
      id: 145,
      value: '4ft 9in – 145cm'
    },
    {
      id: 146,
      value: '4ft 9.5in – 146cm'
    },
    {
      id: 147,
      value: '4ft 10in – 147cm'
    },
    {
      id: 149,
      value: '4ft 10.5in – 149cm'
    },
    {
      id: 150,
      value: '4ft 11in – 150cm'
    },
    {
      id: 151,
      value: '4ft 11.5in – 151cm'
    },
    {
      id: 152,
      value: '5ft – 152cm'
    },
    {
      id: 154,
      value: '5ft 0.5in – 154cm'
    },
    {
      id: 155,
      value: '5ft 1in – 155cm'
    },
    {
      id: 156,
      value: '5ft 1.5in – 156cm'
    },
    {
      id: 157,
      value: '5ft 2in – 157cm'
    },
    {
      id: 159,
      value: '5ft 2.5in – 159cm'
    },
    {
      id: 160,
      value: '5ft 3in – 160cm'
    },
    {
      id: 161,
      value: '5ft 3.5in – 161cm'
    },
    {
      id: 163,
      value: '5ft 4in – 163cm'
    },
    {
      id: 164,
      value: '5ft 4.5in – 164cm'
    },
    {
      id: 165,
      value: '5ft 5in – 165cm'
    },
    {
      id: 166,
      value: '5ft 5.5in – 166cm'
    },
    {
      id: 168,
      value: '5ft 6in – 168cm'
    },
    {
      id: 169,
      value: '5ft 6.5in – 169cm'
    },
    {
      id: 170,
      value: '5ft 7in – 170cm'
    },
    {
      id: 171,
      value: '5ft 7.5in – 171cm'
    },
    {
      id: 173,
      value: '5ft 8in – 173cm'
    },
    {
      id: 174,
      value: '5ft 8.5in – 174cm'
    },
    {
      id: 175,
      value: '5ft 9in – 175cm'
    },
    {
      id: 177,
      value: '5ft 9.5in – 177cm'
    },
    {
      id: 178,
      value: '5ft 10in – 178cm'
    },
    {
      id: 179,
      value: '5ft 10.5in – 179cm'
    },
    {
      id: 180,
      value: '5ft 11in – 180cm'
    },
    {
      id: 182,
      value: '5ft 11.5in – 182cm'
    },
    {
      id: 183,
      value: '6ft – 183cm'
    },
    {
      id: 184,
      value: '6ft 0.5in – 184cm'
    },
    {
      id: 185,
      value: '6ft 1in – 185cm'
    },
    {
      id: 187,
      value: '6ft 1.5in – 187cm'
    },
    {
      id: 188,
      value: '6ft 2in – 188cm'
    },
    {
      id: 189,
      value: '6ft 2.5in – 189cm'
    },
    {
      id: 191,
      value: '6ft 3in – 191cm'
    },
    {
      id: 192,
      value: '6ft 3.5in – 192cm'
    },
    {
      id: 193,
      value: '6ft 4in – 193cm'
    },
    {
      id: 194,
      value: '6ft 4.5in – 194cm'
    },
    {
      id: 196,
      value: '6ft 5in – 196cm'
    },
    {
      id: 197,
      value: '6ft 5.5in – 197cm'
    },
    {
      id: 198,
      value: '6ft 6in – 198cm'
    },
    {
      id: 199,
      value: '6ft 6.5in – 199cm'
    },
    {
      id: 201,
      value: '6ft 7in – 201cm'
    },
    {
      id: 202,
      value: '6ft 7.5in – 202cm'
    },
    {
      id: 203,
      value: '6ft 8in – 203cm'
    },
    {
      id: 204,
      value: '6ft 8.5in – 204cm'
    },
    {
      id: 206,
      value: '6ft 9in – 206cm'
    },
    {
      id: 207,
      value: '6ft 9.5in – 207cm'
    },
    {
      id: 208,
      value: '6ft 10in – 208cm'
    },
    {
      id: 210,
      value: '6ft 10.5in – 210cm'
    },
    {
      id: 211,
      value: '6ft 11in – 211cm'
    },
    {
      id: 212,
      value: '6ft 11.5in – 212cm'
    },
    {
      id: 213,
      value: '7ft – 213cm or more'
    }
  ];
  exploreDataArray = {
    featuredMatch: [],
    recentlyJoined: [],
    recentlyActive: [],
    viewedMe: [],
    profileMayLike: [],
    shortlisted: [],
    viewedByMe: [],
    ingored: []
  };
  storedPrefData = {
    idealArray: [],
    acceptableArray: [],
    importance: '',
    strict: false
  };
  checkedPref = true;
  amplitudeData;
  customFbEventsData;
  ownSocioReligiousData = [
    {
      id: 0,
      value: null
    },
    {
      id: 1,
      value: null
    }
  ];
  ownSocioCommunityData = [
    {
      id: 0,
      value: null
    },
    {
      id: 1,
      value: null
    }
  ];
  ownSocioEthnicData = [
    {
      id: 0,
      value: null
    },
    {
      id: 1,
      value: null
    }
  ];
  completeSectionOwnProfileData;
  completeSectionOtherProfileData;
  headerHeading = new Subject<any>();
  progressValue = new Subject<any>();
  addPhotoslotNumber = [];
  images = [];
  allProfileData = [];
  exploreLoadFirstTime = 1;
  countBrowseHeaderClicked = 1;
  clickPreferenceHighlighter = false;
  screeningBrowseData = [];
  // otherProfileUserData;

  accountHibernationRequested = new Subject<boolean>();
  // Notification-Settings Data
  InterestReceivedNotificationData = {
    push: false,
    email: false
  }
  InterestAcceptNotificationData = {
    push: false,
    email: false
  }
  MatchAddPhotoNotificationData = {
    push: false,
    email: false
  }
  MessageSendNotificationData = {
    push: false,
    email: false
  }

  eventHighlightVariable = true;

  CreateProfileImageData: any = {
    imageData: null,
    hashData: null,
    slotNumber: null,
    editable: false,
    description: null,
    privacy: 1,
    isProfile: null,
    declined: null,
    declinereason: null,
    imageStatus: null,
    countApprovedPhotos: 0
  };

  onBoardingHelpIcon: any = {
    currentPageQue: '',
    fromValue: ''
  }

  blockProfileData: any = {
    userId: null,
    userAlias: null,
    userProfilePic: null,
    from: null,
    blockType: null
  };
  prefsTempContainer = new Subject<any>();
  preferencesData = [
    null, // for age 0
    null, // for martial 1
    null, // for location 2
    null, // for already has child 3
  ];
  // update allNotification data
  getAllNotificationData = new Subject<any>();
  // browse works
  browsedData: any = [];
  photoGallaryData = [];
  currentBrowseProfileIndex = 0;
  browsedProfileCount = new Subject<boolean>();
  browseActionButtonPress = 0;
  browseLimitCount = 1;

  // notification work
  receivedInterestCount = new Subject<number>();
  unfilterInterestCount = new Subject<number>();
  mutualCount = new Subject<number>();
  receivedRequest = new Subject<number>();
  unfilterRequestCount = new Subject<number>();
  interestDecline = new Subject<number>();
  jodiAlertNotificationCount = new Subject<number>();
  hasAnyNotification = new Subject<boolean>();
  totalBrowseCount = 0;
  assistedSignUp = false;
  profileManegmentFirstTimeVisit = false;

  // previousUrl and CurrentUrl
  previousUrl: string = null;
  currentUrl: string = null;

  // inbox works
  unreadMessageCount = new Subject<any>();

  // own data
  ownProfileData: any;
  getOwnProfileData = new Subject<any>();
  interestInYouCount = new Subject<number>();
  getAllNotificationUpdateData: any;

  //notification settings data
  getnotificationSettingsData = new Subject<any>();
  stopNotificationApiCall = false;
  notificatioSettingData;

  forActiveCursor = false;
  forDiwaliOffer = false;
  isquickPlanBanner = false;
  isExpressPlanBanner = false;
  isAssistplanBanner = false;
  oneWeekFreePlanBanner = false;
  oneWeekFreePlanscreeningBanner = false;
  forOneWeekSuccessModel = false;
  forOneWeekShowModel = false;
  emailBanner = false;
  checkComingFromUploadDiplomaPhotoId = false;
  // to check internet connection
  online$: Observable<boolean>;

  // tslint:disable:max-line-length
  routePathMap = [
    {
      currentQN: 0,
      navigationPath: 'user-account-created' // contact number
    },
    {
      currentQN: 1,
      navigationPath: 'basicsq1' // for whom
    },
    {
      currentQN: 2,
      navigationPath: 'basicsq2' // timeframe
    },
    {
      currentQN: 3,
      navigationPath: 'basicsq3' // gender
    },
    {
      currentQN: 4,
      navigationPath: 'basicsq4' // current location
    },
    {
      currentQN: 5,
      navigationPath: 'basicsq6' // residency status
    },
    {
      currentQN: 6,
      navigationPath: 'basicsq7' // willing to relocate
    },
    {
      currentQN: 7,
      navigationPath: 'basicsq8' // date of birth
    },
    {
      currentQN: 8,
      navigationPath: 'basicsq9' // marital staus
    },
    {
      currentQN: 9,
      navigationPath: 'appearanceq1' // height
    },
    {
      currentQN: 10,
      navigationPath: 'appearanceq2' // bodytype
    },
    {
      currentQN: 11,
      navigationPath: 'appearanceq3' // complexion
    },
    {
      currentQN: 12,
      navigationPath: 'appearanceq4' // hair and eye
    },
    {
      currentQN: 13,
      navigationPath: 'appearanceq5' // piercing
    },
    {
      currentQN: 14,
      navigationPath: 'appearanceq6' // tattoo
    },
    {
      currentQN: 15,
      navigationPath: 'eduq1' // level of education
    },
    {
      currentQN: 16,
      navigationPath: 'eduq2' // occupation
    },
    {
      currentQN: 17,
      navigationPath: 'eduq3' // income
    },
    {
      currentQN: 18,
      navigationPath: 'eduq4' // socio economic
    },
    {
      currentQN: 19,
      navigationPath: 'socioculq1' // grewup in
    },
    {
      currentQN: 20,
      navigationPath: 'socioculq1/othercity' // other city
    },
    {
      currentQN: 21,
      navigationPath: 'socioculq3' // religion
    },
    {
      currentQN: 22,
      navigationPath: 'socioculq4' // pray
    },
    {
      currentQN: 23,
      navigationPath: 'socioculq5' // language
    },
    {
      currentQN: 24,
      navigationPath: 'socioculq6' // ethnic
    },
    {
      currentQN: 25,
      navigationPath: 'socioculq7' // astrology
    },
    {
      currentQN: 26,
      navigationPath: 'lifestyleq1' // future child
    },
    {
      currentQN: 27,
      navigationPath: 'lifestyleq2' // future live situation
    },
    {
      currentQN: 28,
      navigationPath: 'lifestyleq3' // food approach
    },
    {
      currentQN: 29,
      navigationPath: 'lifestyleq4' // diatert prefs
    },
    {
      currentQN: 30,
      navigationPath: 'lifestyleq5' // smoking
    },
    {
      currentQN: 31,
      navigationPath: 'lifestyleq6' // drinking
    },
    {
      currentQN: 32,
      navigationPath: 'lifestyleq7' // exercise
    },
    {
      currentQN: 33,
      navigationPath: 'about-family'  // family detail family involvement
    },
    {
      currentQN: 34,
      navigationPath: 'about-family' // family details occupation
    },
    {
      currentQN: 35,
      navigationPath: 'about-family' // family details siblings
    },
    {
      currentQN: 36,
      navigationPath: 'about-family' // family details location
    },
    {
      currentQN: 37,
      navigationPath: 'summary/own/description' // myself hashtags
    },
    {
      currentQN: 38,
      navigationPath: 'add-prefs' // preferences for match
    },
    {
      currentQN: 39,
      navigationPath: 'summary/partner/description' // partner hashtags
    },
    {
      currentQN: 40,
      navigationPath: 'add-photos' // upload photo
    },
    {
      currentQN: 41,
      navigationPath: '' // selfie verification
    },
    {
      currentQN: 42,
      navigationPath: '' // for redirecting to eigther phone verify or scrrening
    }
  ];

  onBordingContactUsFromValue = [
    {
      currentQN: '1',
      fromValue: 'For whom', // for whom
      navigateUrl: '/register/basicsq1'
    },
    {
      currentQN: '1a',
      fromValue: 'Prospect page', // Prospect page
      navigateUrl: '/register/basicsq1/details'
    },
    {
      currentQN: '2',
      fromValue: 'Time frame', // timeframe
      navigateUrl: '/register/basicsq2'
    },
    {
      currentQN: '3',
      fromValue: 'Sex', // gender
      navigateUrl: '/register/basicsq3'
    },
    {
      currentQN: '4',
      fromValue: 'Current city', // current location
      navigateUrl: '/register/basicsq4'
    },
    {
      currentQN: '5',
      fromValue: 'Residency status', // residency status
      navigateUrl: '/register/basicsq6'
    },
    {
      currentQN: '5a',
      fromValue: 'Visa validity', // Visa validity
      navigateUrl: '/register/basicsq6/visadetails'
    },
    {
      currentQN: '5b',
      fromValue: 'Citizen of', // Citizen of
      navigateUrl: '/register/basicsq6/citizen-details'
    },
    {
      currentQN: '5c',
      fromValue: 'Intend to reside permanently', // Citizen of
      navigateUrl: '/register/basicsq6/permanent-resident'
    },
    {
      currentQN: '6',
      fromValue: 'Willing to Relocate', // willing to relocate
      navigateUrl: '/register/basicsq7'
    },
    {
      currentQN: '7',
      fromValue: 'DOB', // date of birth
      navigateUrl: '/register/basicsq8'
    },
    {
      currentQN: '8',
      fromValue: 'Marital Status', // marital staus
      navigateUrl: '/register/basicsq9'
    },
    {
      currentQN: '8a',
      fromValue: 'Number of Children', // Number of Children 
      navigateUrl: '/register/basicsq9/current-children'
    },
    {
      currentQN: '8b',
      fromValue: 'Children living with', // Children living with
      navigateUrl: '/register/basicsq9/living-children'
    },
    {
      currentQN: '9',
      fromValue: 'Height', // height
      navigateUrl: '/register/appearanceq1'
    },
    {
      currentQN: '10',
      fromValue: 'Body type', // bodytype
      navigateUrl: '/register/appearanceq2'
    },
    {
      currentQN: '11',
      fromValue: 'Piercing', // piercing --complexion
      navigateUrl: '/register/appearanceq5'
    },
    {
      currentQN: '12',
      fromValue: 'Tattoo', // tattoo --hair and eye
      navigateUrl: '/register/appearanceq6'
    },
    // {
    //   currentQN: 13,
    //   fromValue: 'appearanceq5' // piercing
    // },
    // {
    //   currentQN: 14,
    //   fromValue: 'appearanceq6' // tattoo
    // },
    {
      currentQN: '13',
      fromValue: 'Highest level of Education', // level of education
      navigateUrl: '/register/eduq1'
    },
    {
      currentQN: '13a',
      fromValue: 'Degree and College', //   Degree and College
      navigateUrl: '/register/eduq1/edu-details'
    },
    {
      currentQN: '14',
      fromValue: 'Nature of Employment', // occupation
      navigateUrl: '/register/eduq2'
    },
    {
      currentQN: '14a',
      fromValue: 'Current occupation', // Current occupation
      navigateUrl: '/register/eduq2/occupation-details'
    },
    {
      currentQN: '14b',
      fromValue: 'Company name', //Company name
      navigateUrl: '/register/eduq2/organization-details'
    },
    {
      currentQN: '15',
      fromValue: 'Income', // income
      navigateUrl: '/register/eduq3'
    },
    {
      currentQN: '16',
      fromValue: 'Socio-Economic Status', // socio economic
      navigateUrl: '/register/eduq4'
    },
    {
      currentQN: '17',
      fromValue: 'Grew up in 1', // grewup in
      navigateUrl: '/register/socioculq1'
    },
    {
      currentQN: '18',
      fromValue: 'Grew up in 2', // other city
      navigateUrl: '/register/socioculq1/othercity'
    },
    {
      currentQN: '19',
      fromValue: 'Religion', // religion 
      navigateUrl: '/register/socioculq3'
    },
    {
      currentQN: '20',
      fromValue: 'Praying Habits', // pray
      navigateUrl: '/register/socioculq4'
    },
    {
      currentQN: '21',
      fromValue: 'Mother Tongue', // language
      navigateUrl: '/register/socioculq5'
    },
    {
      currentQN: '21a',
      fromValue: 'English Fluency', // language
      navigateUrl: '/register/socioculq5/primary'
    },
    {
      currentQN: '21b',
      fromValue: 'Other Language', // Other Language
      navigateUrl: '/register/socioculq5/other'
    },
    {
      currentQN: '21c',
      fromValue: 'Community/Caste', // Community
      navigateUrl: '/register/socioculq5/community'
    },
    {
      currentQN: '22',
      fromValue: 'Ethinic', // ethnic
      navigateUrl: '/register/socioculq6'
    },
    {
      currentQN: '23',
      fromValue: 'Horoscope', // astrology
      navigateUrl: '/register/socioculq7'
    },
    {
      currentQN: '24',
      fromValue: 'More own children', // future child
      navigateUrl: '/register/lifestyleq1'
    },
    {
      currentQN: '25',
      fromValue: 'Living situation', // future live situation
      navigateUrl: '/register/lifestyleq2'
    },
    {
      currentQN: '26',
      fromValue: 'Approach to food', // food approach
      navigateUrl: '/register/lifestyleq3'
    },
    {
      currentQN: '27',
      fromValue: 'Dietary preferences', // diatert prefs
      navigateUrl: '/register/lifestyleq4'
    },
    {
      currentQN: '28',
      fromValue: 'Smoking Habits', // smoking
      navigateUrl: '/register/lifestyleq5'
    },
    {
      currentQN: '29',
      fromValue: 'Drinking Habits', // drinking
      navigateUrl: '/register/lifestyleq6'
    },
    {
      currentQN: '30',
      fromValue: 'Exercise Habits', // exercise
      navigateUrl: '/register/lifestyleq7'
    },
    {
      currentQN: '31',
      fromValue: 'Family involvement',  // family detail family involvement
      navigateUrl: '/register/about-family'
    },
    {
      currentQN: '32',
      fromValue: 'Parents Occupation', // family details occupation 
      navigateUrl: '/register/about-family/occupation'
    },
    {
      currentQN: '33',
      fromValue: 'Sibling count', // family details siblings 
      navigateUrl: '/register/about-family/siblings'
    },
    {
      currentQN: '34',
      fromValue: 'Family location', // family details location
      navigateUrl: '/register/about-family/location'
    },
    {
      currentQN: '35',
      fromValue: 'More About Myself', // myself hashtags
      navigateUrl: '/register/summary/own/description'
    },
    {
      currentQN: '36',
      fromValue: 'Preferences', // preferences for match
      navigateUrl: '/register/add-prefs'
    },
    {
      currentQN: '37',
      fromValue: 'More about partner', // partner hashtags 
      navigateUrl: '/register/summary/partner/description'
    },
    {
      currentQN: '38',
      fromValue: 'Main page', // upload photo 
      navigateUrl: '/register/add-photos'

    },
    {
      currentQN: '38a',
      fromValue: 'Camera/Gallery', // upload photo
      navigateUrl: '/register/add-photos/info'
    },
    {
      currentQN: '38b',
      fromValue: 'Photo Modal', // upload photo
      navigateUrl: '/register/add-photos/details'
    },
    {
      currentQN: '39',
      fromValue: 'Info page', // Video Verification Info page /register/video-verification/overview
      navigateUrl: '/register/video-verification/overview'
    },
    {
      currentQN: '39a',
      fromValue: 'Video Recorder', // Video Verification Video Recorder
      navigateUrl: '/register/video-verification/record'
    },
    {
      currentQN: '40',
      fromValue: '' // for redirecting to eigther phone verify or scrrening
    }
  ]


  constructor(private router: Router, private snackbar: MatSnackBar, private localservice: LocalStorageService, private apiservice: ApiService) { }

  boxBlurCanvasRGBA(id, top_x, top_y, width, height, radius, iterations) {
    const mul_table = [1, 57, 41, 21, 203, 34, 97, 73, 227, 91, 149, 62, 105, 45, 39, 137, 241, 107, 3, 173, 39, 71, 65, 238, 219, 101, 187, 87, 81, 151, 141, 133, 249, 117, 221, 209, 197, 187, 177, 169, 5, 153, 73, 139, 133, 127, 243, 233, 223, 107, 103, 99, 191, 23, 177, 171, 165, 159, 77, 149, 9, 139, 135, 131, 253, 245, 119, 231, 224, 109, 211, 103, 25, 195, 189, 23, 45, 175, 171, 83, 81, 79, 155, 151, 147, 9, 141, 137, 67, 131, 129, 251, 123, 30, 235, 115, 113, 221, 217, 53, 13, 51, 50, 49, 193, 189, 185, 91, 179, 175, 43, 169, 83, 163, 5, 79, 155, 19, 75, 147, 145, 143, 35, 69, 17, 67, 33, 65, 255, 251, 247, 243, 239, 59, 29, 229, 113, 111, 219, 27, 213, 105, 207, 51, 201, 199, 49, 193, 191, 47, 93, 183, 181, 179, 11, 87, 43, 85, 167, 165, 163, 161, 159, 157, 155, 77, 19, 75, 37, 73, 145, 143, 141, 35, 138, 137, 135, 67, 33, 131, 129, 255, 63, 250, 247, 61, 121, 239, 237, 117, 29, 229, 227, 225, 111, 55, 109, 216, 213, 211, 209, 207, 205, 203, 201, 199, 197, 195, 193, 48, 190, 47, 93, 185, 183, 181, 179, 178, 176, 175, 173, 171, 85, 21, 167, 165, 41, 163, 161, 5, 79, 157, 78, 154, 153, 19, 75, 149, 74, 147, 73, 144, 143, 71, 141, 140, 139, 137, 17, 135, 134, 133, 66, 131, 65, 129, 1];


    const shg_table = [0, 9, 10, 10, 14, 12, 14, 14, 16, 15, 16, 15, 16, 15, 15, 17, 18, 17, 12, 18, 16, 17, 17, 19, 19, 18, 19, 18, 18, 19, 19, 19, 20, 19, 20, 20, 20, 20, 20, 20, 15, 20, 19, 20, 20, 20, 21, 21, 21, 20, 20, 20, 21, 18, 21, 21, 21, 21, 20, 21, 17, 21, 21, 21, 22, 22, 21, 22, 22, 21, 22, 21, 19, 22, 22, 19, 20, 22, 22, 21, 21, 21, 22, 22, 22, 18, 22, 22, 21, 22, 22, 23, 22, 20, 23, 22, 22, 23, 23, 21, 19, 21, 21, 21, 23, 23, 23, 22, 23, 23, 21, 23, 22, 23, 18, 22, 23, 20, 22, 23, 23, 23, 21, 22, 20, 22, 21, 22, 24, 24, 24, 24, 24, 22, 21, 24, 23, 23, 24, 21, 24, 23, 24, 22, 24, 24, 22, 24, 24, 22, 23, 24, 24, 24, 20, 23, 22, 23, 24, 24, 24, 24, 24, 24, 24, 23, 21, 23, 22, 23, 24, 24, 24, 22, 24, 24, 24, 23, 22, 24, 24, 25, 23, 25, 25, 23, 24, 25, 25, 24, 22, 25, 25, 25, 24, 23, 24, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 23, 25, 23, 24, 25, 25, 25, 25, 25, 25, 25, 25, 25, 24, 22, 25, 25, 23, 25, 25, 20, 24, 25, 24, 25, 25, 22, 24, 25, 24, 25, 24, 25, 25, 24, 25, 25, 25, 25, 22, 25, 25, 25, 24, 25, 24, 25, 18];

    if (isNaN(radius) || radius < 1) { return; }

    radius |= 0;

    if (isNaN(iterations)) { iterations = 1; }
    iterations |= 0;
    if (iterations > 3) { iterations = 3; }
    if (iterations < 1) { iterations = 1; }

    const canvas = document.getElementById(id) as HTMLCanvasElement;
    const context = canvas.getContext('2d');
    const imageData = context.getImageData(0, 0, width, height);
    /* try {
         try {
             imageData = context.getImageData(top_x, top_y, width, height);
         } catch (e) {

             // NOTE: this part is supposedly only needed if you want to work with local files
             // so it might be okay to remove the whole try/catch block and just use
             // imageData = context.getImageData( top_x, top_y, width, height );
             try {
                 netscape.security.PrivilegeManager.enablePrivilege("UniversalBrowserRead");
                 imageData = context.getImageData(top_x, top_y, width, height);
             } catch (e) {
                 alert("Cannot access local image");
                 throw new Error("unable to access local image data: " + e);
                 return;
             }
         }
     } catch (e) {
         alert("Cannot access image");
         throw new Error("unable to access image data: " + e);
         return;
     }*/

    const pixels = imageData.data;

    let rsum, gsum, bsum, asum, x, y, i, p, p1, p2, yp, yi, yw, idx, pa;
    const wm = width - 1;
    const hm = height - 1;
    const wh = width * height;
    const rad1 = radius + 1;

    const mul_sum = mul_table[radius];
    const shg_sum = shg_table[radius];

    const r = [];
    const g = [];
    const b = [];
    const a = [];

    const vmin = [];
    const vmax = [];

    while (iterations-- > 0) {
      yw = yi = 0;

      for (y = 0; y < height; y++) {
        rsum = pixels[yw] * rad1;
        gsum = pixels[yw + 1] * rad1;
        bsum = pixels[yw + 2] * rad1;
        asum = pixels[yw + 3] * rad1;


        for (i = 1; i <= radius; i++) {
          p = yw + (((i > wm ? wm : i)) << 2);
          rsum += pixels[p++];
          gsum += pixels[p++];
          bsum += pixels[p++];
          asum += pixels[p];
        }

        for (x = 0; x < width; x++) {
          r[yi] = rsum;
          g[yi] = gsum;
          b[yi] = bsum;
          a[yi] = asum;

          if (y === 0) {
            vmin[x] = ((p = x + rad1) < wm ? p : wm) << 2;
            vmax[x] = ((p = x - radius) > 0 ? p << 2 : 0);
          }

          p1 = yw + vmin[x];
          p2 = yw + vmax[x];

          rsum += pixels[p1++] - pixels[p2++];
          gsum += pixels[p1++] - pixels[p2++];
          bsum += pixels[p1++] - pixels[p2++];
          asum += pixels[p1] - pixels[p2];

          yi++;
        }
        yw += (width << 2);
      }

      for (x = 0; x < width; x++) {
        yp = x;
        rsum = r[yp] * rad1;
        gsum = g[yp] * rad1;
        bsum = b[yp] * rad1;
        asum = a[yp] * rad1;

        for (i = 1; i <= radius; i++) {
          yp += (i > hm ? 0 : width);
          rsum += r[yp];
          gsum += g[yp];
          bsum += b[yp];
          asum += a[yp];
        }

        yi = x << 2;
        for (y = 0; y < height; y++) {

          pixels[yi + 3] = pa = (asum * mul_sum) >>> shg_sum;
          if (pa > 0) {
            pa = 255 / pa;
            pixels[yi] = ((rsum * mul_sum) >>> shg_sum) * pa;
            pixels[yi + 1] = ((gsum * mul_sum) >>> shg_sum) * pa;
            pixels[yi + 2] = ((bsum * mul_sum) >>> shg_sum) * pa;
          } else {
            pixels[yi] = pixels[yi + 1] = pixels[yi + 2] = 0;
          }
          if (x === 0) {
            vmin[y] = ((p = y + rad1) < hm ? p : hm) * width;
            vmax[y] = ((p = y - radius) > 0 ? p * width : 0);
          }

          p1 = x + vmin[y];
          p2 = x + vmax[y];

          rsum += r[p1] - r[p2];
          gsum += g[p1] - g[p2];
          bsum += b[p1] - b[p2];
          asum += a[p1] - a[p2];

          yi += width << 2;
        }
      }
    }

    context.putImageData(imageData, top_x, top_y);

  }

   callForCreatingLocalStorageKey(index) {
    this.apiservice.getownprofile().subscribe(result => {
      const currQue = this.routePathMap[index].currentQN;
      let section;
      if (currQue >= 1 && currQue <= 2) {
        section = 'basics1'
      } else if (currQue >= 3 && currQue <= 9) {
        section = 'basics2'
      } else if (currQue >= 9 && currQue <= 14) {
        section = 'apperance'
      } else if (currQue >= 15 && currQue <= 18) {
        section = 'education'
      } else if (currQue >= 19 && currQue <= 25) {
        section = 'socio'
      } else if (currQue >= 26 && currQue <= 32) {
        section = 'lifestyle'
      } else if (currQue >= 33 && currQue <= 36) {
        section = 'family'
      }

      switch (section) {
        case 'basics1':
          if (result.userdata[0].basics.marriagetimeframe && result.userdata[0].basics.opentotimeframe) {
            const params = {
              forWhom: result.userdata[0].basics.marriagetimeframe,
              timeFrame: result.userdata[0].basics.opentotimeframe
            }
            this.localservice.setToLocal('profileManagement', params);
          }

          if (result.userdata[0].basics.prospectfirstname) {
            const params = {
              fname: result.userdata[0].basics.prospectfirstname,
              lname: result.userdata[0].basics.prospectlastname,
              managedBy: result.userdata[0].basics.profilemanagedby === 'Self' ? 'No' : 'Yes'
            }
            console.log(params);
            this.localservice.setToLocal('basicDetails', params);
          }
          break;
        case 'basics2':
          if (result.userdata[0].basics) {
            const params = {
              gender: result.userdata[0].basics.ismale ? 'Male' : 'Female',
              locationData: result.userdata[0].basics.location.currentloc,
              clickOption: result.userdata[0].basics?.residencystatus === 'Citizen' ? 'Yes' : 'No',
              relocation: result.userdata[0].basics.willingtorelocate,
              dobPageInfo: {
                dob: result.userdata[0].basics.dob,
                revealsunsign: result.userdata[0].background.revealsunsign === 1 ? true : false
              },
              maritalInfo: {
                status: result.userdata[0].basics.maritalstatus,
                maritalsince: result.userdata[0].basics.maritalsince,
                currentChild: result.userdata[0].basics.childcount === 'Not applicable' ? 'Not Applicable' : result.userdata[0].basics.childcount,
                livingChild: result.userdata[0].basics.liveinchildcount
              }
            }
            this.localservice.setToLocal('basicData', params);
          }

          break;
        case 'apperance':
          if (result.userdata[0].appearance) {
            const params = {
              height: result.userdata[0].appearance.height,
              bodyType: result.userdata[0].appearance.bodytype,
              piercing: result.userdata[0].appearance.piercing,
              tattoo: result.userdata[0].appearance.tattoo
            }
            this.localservice.setToLocal('apperanceData', params);
          }

          break;
        case 'education':
          if (result.userdata[0].education) {
            const datatoLocalService = {
              nature: result.userdata[0].education.natureofemployment,
              occupation: result.userdata[0].education.currentoccupation,
              companyName: result.userdata[0].education.companyname,
              revealcompany: result.userdata[0].education.revealcompany
            };
            this.localservice.setToLocal('natureOfEmployment',datatoLocalService);
          }
          if (result.userdata[0].education.income) {
          const params = {
            incomeDetails: {
              income: result.userdata[0].education.income,
              revelIncome:  result.userdata[0].education.revealincome ? true : false
            },
            socioEcoData: result.userdata[0].education.socioeconomicstatus.split(' (')[0]
          }
          this.localservice.setToLocal('eduData', params);
        }
        if (result.userdata[0].education) {
                const localData = result.userdata[0].education.highestedulevel;
                this.localservice.setToLocal('eduq1-user-education', localData);         
        }
        break;
        case 'socio':
           // setToLocal sociocul1data for back button
           if(result.userdata[0].background) {
            const dataObj = [
              {
                city: null,
                state: null,
                country: null,
                id: null,
                liveagain: null
              },
              {
                city: null,
                state: null,
                country: null,
                id: null,
                liveagain: null
              }
            ];
            const t = result.userdata[0].background;
              if (t.grewupin) {
                dataObj[0].liveagain = t.grewupin[0].grewupinliveagain;
                const temp = t.grewupin[0].grewuplocation.split(', ');
                dataObj[0].city = temp[0];
                dataObj[0].id = t.grewupin[0].nodeid;
                if (temp[2]) {
                  dataObj[0].state = temp[1];
                  dataObj[0].country = temp[2];
                } else {
                  dataObj[0].state = null;
                  dataObj[0].country = temp[1];
                }
                if (t.grewupin.length === 2) {
                  dataObj[1].liveagain = t.grewupin[1].grewupinliveagain;
                  const temp2 = t.grewupin[1].grewuplocation.split(', ');
                  dataObj[1].city = temp2[0];
                  dataObj[1].id = t.grewupin[1].nodeid;
                  if (temp2[2]) {
                    dataObj[1].state = temp2[1];
                    dataObj[1].country = temp2[2];
                  } else {
                    dataObj[1].state = null;
                    dataObj[1].country = temp2[1];
                  }
                }
              }
              this.localservice.setToLocal('sociocul1data', dataObj);
      
              // setToLocal religiousData for back button
              if (result.userdata[0].background.religion) {
                const dataArray = [
                  {
                    id: 0,
                    value: null
                  },
                  {
                    id: 1,
                    value: null
                  }
                ];
      
                if (result.userdata[0].background.religion.length > 1) {
                  dataArray[0].value = result.userdata[0].background.religion[0];
                  dataArray[1].value = result.userdata[0].background.religion[1]            
                } else {
                  dataArray[0].value = result.userdata[0].background.religion[0];
                }
                this.localservice.setToLocal('religiousData', dataArray);
              }
              
              // setToLocal socioData for back button
              if (result.userdata[0].background.prayerfrequency) {
                const params = {
                  prayerData: result.userdata[0].background.prayerfrequency,
                  astrologyData: result.userdata[0].background.astrology
                }
                this.localservice.setToLocal('socioData', params);
              }
      
              // setToLocal langdata for back button
              if (result.userdata[0].background.languages) {
                const dataArray = [
                  {
                    language: null,
                    fluency: 'mother tongue / native language'
                  },
                  {
                    language: 'English',
                    fluency: null
                  },
                  {
                    language: null,
                    fluency: null
                  }
                ];
                let nativeFound = false;
                let i =1;
                result.userdata[0].background.languages.forEach((element, index) => {
                  if (element.split(' - ')[1] === 'mother tongue / native language' && !nativeFound) {
                    dataArray[0].language = element.split(' - ')[0];
                    nativeFound = true;
                  } else {
                    dataArray[i].language = element.split(' - ')[0];
                    dataArray[i].fluency = element.split(' - ')[1].charAt(0).toUpperCase() + element.split(' - ')[1].slice(1);
                    i++;
                  }
                });
                this.localservice.setToLocal('langdata', dataArray);
      
              }
      
              // setToLocal communitydata for back button
              if (result.userdata[0].background.caste) {
                const selectedData = [
                  {
                    id: 0,
                    value: null
                  },
                  {
                    id: 1,
                    value: null
                  }
                ];
      
                selectedData[0].value = result.userdata[0].background.caste[0];
                selectedData[1].value = result.userdata[0].background.caste[1] ? result.userdata[0].background.caste[1] : null;
                
                this.localservice.setToLocal('communitydata', selectedData);
      
              }
      
              // setToLocal ethinicdata for back button
              if (result.userdata[0].background.ethnicgroups) {
                const selectedData = [
                  {
                    id: 0,
                    value: null
                  },
                  {
                    id: 1,
                    value: null
                  }
                ];
                selectedData[0].value = result.userdata[0].background.ethnicgroups[0];
                selectedData[1].value = result.userdata[0].background.ethnicgroups[1] ? result.userdata[0].background.ethnicgroups[1] : null;
                this.localservice.setToLocal('ethinicdata', selectedData);
      
              }
           }
           
        break;
        case 'lifestyle':
           if(result.userdata[0].lifestyle) {
            const params = {
              futureChildren: null,
              livingSituation: result.userdata[0].lifestyle.livingSituation,
              approachToFood: result.userdata[0].lifestyle.approachtofood,
              dietPref: result.userdata[0].lifestyle.dietarypreferences,
              smokeHabit: {
                smokeFreq: result.userdata[0].lifestyle.smokefrequency,
                issmokingreduce: result.userdata[0].lifestyle.issmokingreduce,
                revealsmokefrequency: result.userdata[0].lifestyle.revealsmokefrequency
              },
              drinkingHabit: {
                drinkingFreq: result.userdata[0].lifestyle.drinkfrequency,
                isdrinkingreduce: result.userdata[0].lifestyle.isdrinkingreduce,
                revealdrinkfrequency: result.userdata[0].lifestyle.revealdrinkfrequency
              },
              exercise: result.userdata[0].lifestyle.exercisefrequency
            }
            this.localservice.setToLocal('lifestyleData', params);
           }
        break;
        case 'family':
          if (result.userdata[0].family) {
            const params = {
              familyInvolvement: result.userdata[0].family.familyInvolvement,
              familySibling: result.userdata[0].family.siblingsCount
            }
            this.localservice.setToLocal('familyBackground', params);
    
            const dataArrayOcc = [
              {
                id: 0,
                value: null
              },
              {
                id: 1,
                value: null
              }
            ];
            if (result.userdata[0].family.fatherOccupation) {
              dataArrayOcc[0].value = result.userdata[0].family.fatherOccupation;
              if (result.userdata[0].family.motherOccupation) {
                dataArrayOcc[1] = result.userdata[0].family.motherOccupation;
              }
            }
            this.localservice.setToLocal('famOccData', dataArrayOcc);

            const dataArrayLoc = [null,null];
            if (result.userdata[0].family.familyLocation) {
              dataArrayLoc[0] = result.userdata[0].family.familyLocation[0];
              dataArrayLoc[1] = result.userdata[0].family.familyLocation[1];
              }
              this.localservice.setToLocal('famLocData', dataArrayLoc);
            }
        break;
      }
    },
      (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
        if (err.status === 401) {
           this.clearlocalstorageData();
          //this.localservice.removeToken('user');
          this.router.navigate(['/login']);
        } else if (err.status >= 500) {
          this.gettingServerError();
        }
    });
  }

  callGetOwnProfileApi() {

    // console.log('hii');

    this.apiservice.getownprofile().subscribe(res => {
      this.ownProfileData = res.userdata[0];
      this.getOwnProfileData.next({ ...res.userdata[0] });
      const localdata = this.localservice.getFromLocal('user');

      // for plans and packs
      if (this.ownProfileData.plansNpacks.assisted) {
        localdata.accounttype = 'ACP';
      } else if (this.ownProfileData.plansNpacks.express) {
        localdata.accounttype = 'ECP';
      } else if (this.ownProfileData.plansNpacks.quick) {
        localdata.accounttype = 'QCP';
      } else {
        localdata.accounttype = 'Free Forever members';
      }
      this.localservice.setToLocal('user', localdata);
    }, (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
      if (err.status === 401) {
         this.clearlocalstorageData();
          //this.localservice.removeToken('user');
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
        this.gettingServerError();
      }
    });
  }

  userType() {
    const local = this.localservice.getFromLocal('user');
    try {
      if (local) {
        if (local.issignuppending) {
          return 'signUpPending';
        } else {
          if (local.showscreeningpage) {
            return 'screening'
          } else {
            return 'signUpDone';
          }

        }
      } else {
        return 'static'
      }

    } catch (err) {
      console.log('error in getting local data ' + err);
    }
  }


  getAvatarPhoto() {
    const localData = this.localservice.getFromLocal('user');
    if (localData.ismale) {
      return '/assets/images/static/female-avatar-200x200.jpg';
    } else {
      return '/assets/images/static/male-avatar-200x200.jpg';
    }
  }
  getThumbAvatarPhoto() {
    const localData = this.localservice.getFromLocal('user');
    if (localData.ismale) {
      return '/assets/images/static/female-avatar-200x200.jpg';
    } else {
      return '/assets/images/static/male-avatar-200x200.jpg';
    }
  }

  getOwnAvatarPhoto() {
    const localData = this.localservice.getFromLocal('user');
    if (localData.ismale) {
      return '/assets/images/static/male-avatar-200x200.jpg';
    } else {
      return '/assets/images/static/female-avatar-200x200.jpg';
    }
  }

  // cal getAllNotification Api and store it's data in observable type
  updateAllNotification() {
    const errorStatus = this.localservice.getFromLocal('errorstatus');
    this.apiservice.getAllNotificationUpdate().subscribe(res => {
      if (res) {
        if (errorStatus && errorStatus === 'true') {
          this.localservice.removeToken('errorstatus');
          // window.location.reload();
        }
        this.getAllNotificationUpdateData = res;
        this.getAllNotificationData.next(res);
      }

    }, (err: HttpErrorResponse) => {
      if (err.status === 401 || err.status === 400) {
         this.clearlocalstorageData();
          //this.localservice.removeToken('user');
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
        this.gettingServerError();
      }
    });
  }

  updateExploreShortListApi() {
    this.apiservice.getExploreData({ skip: 0, type: 'other', relType: 'shortlisted' }).subscribe(res => {
      this.exploreDataArray.shortlisted = res.result.userdata;
    }, (err: HttpErrorResponse) => {
      if (err.status === 401 || err.status === 400) {
         this.clearlocalstorageData();
          //this.localservice.removeToken('user');
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
        this.gettingServerError();
      }
    });
  }

  updateExploreViewedByMe() {
    this.apiservice.getExploreData({ skip: 0, type: 'other', relType: 'viewedOut' }).subscribe(res => {
      this.exploreDataArray.viewedByMe = res.result.userdata;
    }, (err: HttpErrorResponse) => {
      if (err.status === 401 || err.status === 400) {
         this.clearlocalstorageData();
          //this.localservice.removeToken('user');
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
        this.gettingServerError();
      }
    });
  }

  updateExploreIgnoredProfile() {
    this.apiservice.getExploreData({ skip: 0, type: 'other', relType: 'blocked' }).subscribe(res => {
      this.exploreDataArray.ingored = res.result.userdata;
    }, (err: HttpErrorResponse) => {
      if (err.status === 401 || err.status === 400) {
         this.clearlocalstorageData();
          //this.localservice.removeToken('user');
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
        this.gettingServerError();
      }
    });
  }

  getBrowsedData(): Observable<any[]> {
    return of(this.browsedData);
  }
  setBrowsedData(data) {
    this.browsedData = data;
  }

  isSmallDevice() {
    if (window.innerWidth > 800) {
      return false;
    } else {
      return true;
    }
  }

  // if server error occurs
  gettingServerError() {
    const localData = this.localservice.getFromLocal('user');
    this.localservice.setToLocal('errorstatus', 'true')
    if (localData.issignuppending) {
      this.router.navigate(['/register/oops'], { skipLocationChange: true });
    } else {
      this.router.navigate(['/user/oops'], { skipLocationChange: true });
    }

  }

  // open snackbar
  openSnackbarMessageWithTime(message, time) {
    this.snackbar.open(message, '', {
      duration: time,
      horizontalPosition: 'center'
    });
  }
  // set photo gallary of user
  setPhotoGallarydata(data) {
    this.photoGallaryData = data;
  }
  // get photo gallary of user
  getPhotoGallaryData() {
    return this.photoGallaryData;
  }
  // route navigation for edit profile
  navigateToEditProfile(params) {
    this.router.navigate(['/user/edit-profile/' + params]);
  }

  // track plans and packs of user
  trackPlanPack(from: string, otherId?: number) {
    let params: any;
    if (otherId) {
      params = {
        CTA: from,
        eventcategory: 'PlansPacks',
        otherid: otherId,
        from: 'Mobile Web'
      };
    } else {
      params = {
        CTA: from,
        eventcategory: 'PlansPacks',
        from: 'Mobile Web'
      };
    }

    // call api
    this.apiservice.clickcaptur(params).subscribe(result => {
      if (result) {
        console.log('true');
      }
    });

  }

  // amplitude and clevertap events
  logAnalyticsEvent(eventname, attributes = null) {
   // console.log(eventname);
    const windowTemp: any = window;
    const eventProperties = {
      url: window.location.href
    };
    if (environment.production) {
      track(eventname, { ...eventProperties, ...attributes });
      // windowTemp.clevertap.event.push(eventname, { ...eventProperties, ...attributes });
    }
  }

  // amplitude identifier
  identifyAnalyticsEvent(key, value) {
    if (environment.production) {
      identify(new Identify().set(key, value));
    }

  }
  setUserIdAnalyticsEvent(username) {
    if (environment.production) {
      setUserId(username);
    }

  }
  resetAnalyticsEvent() {
    if (environment.production) {
      setUserId(null);
      reset();
    }
  }
  // fb events
  logcustomFbEvents(eventname) {
  let fbLocalData = this.localservice.getFromLocal('fbUniqueEvents');
  if(!fbLocalData){
    const data:any = {}
    data.events =[];
    fbLocalData = data;
   this.localservice.setToLocal('fbUniqueEvents',data);
  }
    if (environment.production) {
      const uniqueFbEvents = ['sign_up','mWeb_profile_completed','mWeb_order_success'];
      const index = uniqueFbEvents.indexOf(eventname);
    if(index >=0){
        const localIndex = (fbLocalData.events.length > 0) ? fbLocalData.events?.indexOf(eventname) : -1 ;
          if(localIndex == -1){
          fbLocalData.events.push(eventname);
          this.localservice.setToLocal('fbUniqueEvents',fbLocalData);
         this.callFbEvents(this.ownProfileData?.userid, eventname)
        }
  }else {
    this.callFbEvents(this.ownProfileData?.userid, eventname)
  }
    }
  }

  callFbEvents(userid,eventname){
    const windowTemp: any = window;
    if(userid){ 
      windowTemp.fbq('trackCustom', eventname,{
        contents:[{
          userid: userid,
        }]
      });
   }else{
    windowTemp.fbq('trackCustom',eventname);
   }
  }
  // fb events for payment success and failure
  logPaymentCustomFbEvents(eventname, eventdetail) {
    if (environment.production) {
      const windowTemp: any = window;
      windowTemp.fbq('trackCustom', eventname, {
        value: 0.50,
        currency: 'INR',
        contents: [{
          userid: this.ownProfileData?.userid,
          price: eventdetail
        }]
      });
    }
  }
  // timestamp :-
  // less than 24 only time
  // more than 24 but within that year show time + date without year
  // more than 24 but less than that year show time + date with year
  // time stamp is concerted with date pipe its only to differentiate timestamp

  clearlocalstorageData(){
    const requesturl = this.localservice.getFromLocal('RequestUrl');
    const contactusDetails = this.localservice.getFromLocal('contactUsDetails');
      localStorage.clear();
      if(requesturl){
        this.localservice.setToLocal('RequestUrl',requesturl);
      }
      if(contactusDetails){
        this.localservice.setToLocal('contactUsDetails',contactusDetails);
      }
  }
  showActiveTime1(accesstime: number): string {
    try {
      const currentTimeStamp = new Date().getTime();
      const twentyFourHourTimeStamp = 86400000;
      const fourtyEightHourTimeStamp = 172800000;
      const currentMinusAccess = currentTimeStamp - accesstime;
      const accessDate = new Date(accesstime);
      if (currentMinusAccess < twentyFourHourTimeStamp) {
        // return 'day';  less than a day
        console.log("today");
        return "Today";
      } else if (currentMinusAccess > twentyFourHourTimeStamp && currentMinusAccess < fourtyEightHourTimeStamp) {
        console.log("yesterday");
        return "Yesterday";
      } else if (new Date(currentTimeStamp).getFullYear() - (accessDate.getFullYear()) === 0) {
        console.log("within year");
        return 'within year'; // less than a  year
      } else {
        return 'greater'; // greater than a year
      }
    } catch (error) {
      console.log(error);
    }
  }


  getOnlineStatus(accesstime: number) {
    if (accesstime) {
      const currentTimeStamp = new Date().getTime();
      const twentyFourHourTimeStamp = 86400000;
      const currentMinusAccess = currentTimeStamp - accesstime;
      if (currentMinusAccess < (twentyFourHourTimeStamp * 3)) {
        if (currentTimeStamp - accesstime < 300000) {
          return 'Online';
        } else {
          return 'Recently active'
        }
      }
      else {
        return null;
      }
    }
  }

  // return height data on the basis of height in cm
  getConvertedHeight(data) {
    const heightIncm = this.heightData.find(e => e.id === data);
    return heightIncm.value;
  }

  // get otherprofiledata
  // getOtherprofileData(params) {
  //   this.otherProfileUserAlias = params.alias;

  // }

  // get currentQuestion number through local and redirecting  according to that
  getRouteForRegister(currentQuestionNumber: number, isPhoneVerified?: boolean) {
    const questionIndex = this.routePathMap.findIndex(index => index.currentQN === currentQuestionNumber);
    if (questionIndex > -1) {
      // console.log(currentQuestionNumber);
      if(currentQuestionNumber === 41 || currentQuestionNumber === 42){
        return !isPhoneVerified ? 'verify-phone-number' : 'screening';

      } 
      // else if(currentQuestionNumber === 42) {

      //   return !isPhoneVerified ? 'verify-phone-number' : 'screening';
      //   // console.log('verify phone');
      //   // this.router.navigate(['/register/verify-phone-number']);
      //   // return 'verify-phone-number';
      // } 
      else {
        // this.navigateToDesiredPlace(this.routePathMap[questionIndex].navigationPath);
        // this.router.navigate(['/register/' + this.routePathMap[questionIndex].navigationPath]);
        this.callForCreatingLocalStorageKey(questionIndex)
        return this.routePathMap[questionIndex].navigationPath;
      }
    }
  }
  getRouteForFromValue(currentQuestionNumber: string, isPhoneVerified?: boolean) {
    const questionIndex = this.onBordingContactUsFromValue.findIndex(index => index.currentQN === currentQuestionNumber);
    if (questionIndex > -1) {
      // console.log(currentQuestionNumber);

      if (currentQuestionNumber === '41') {

        return !isPhoneVerified ? 'verify-phone-number' : 'screening';
        // console.log('verify phone');
        // this.router.navigate(['/register/verify-phone-number']);
        // return 'verify-phone-number';
      } else {
        // this.navigateToDesiredPlace(this.routePathMap[questionIndex].navigationPath);
        // this.router.navigate(['/register/' + this.routePathMap[questionIndex].navigationPath]);
        // console.log(this.routePathMap[questionIndex].navigationPath);
        return this.onBordingContactUsFromValue[questionIndex].fromValue;
      }
    }
  }

  navigate(param) {

    const userType = this.userType();

    switch (userType) {
      case 'signUpPending':
        this.router.navigate(['/register/' + param]);
        break;
      case 'screening':
        this.router.navigate(['/user/' + param]);
        break;
      case 'signUpDone':
        this.router.navigate(['/user/' + param]);
        break;
      case 'static':
        this.router.navigate(['/' + param]);
        break;

      default:
        this.router.navigate(['/' + param]);
        break;
    }

  }

  UpdateNotificationSettingData() {
    //console.log("update data");
    this.apiservice.getnotificationsettings().subscribe(result => {
      this.stopNotificationApiCall = true;
      this.notificatioSettingData = result;
      this.getnotificationSettingsData.next(result);
    });
  }

  oneWeekFreePlan() {
    const ownprofiledata = this.ownProfileData;
    if (ownprofiledata) {


      // let forSuccessOneWeekModel  = this.sharedservice.forOneWeekSuccessModel;
      // let forShowOneWeekModel = this.sharedservice.forOneWeekShowModel;
      //let isModelShow = this.localservice.getFromLocal('NotShowAgain');
      const isModelSuccessShow = this.localservice.getFromLocal('NotShowSuccessModel');
      const currentDate = new Date().getTime();
      // console.log(currentDate);

      let oneWeekPlanSuccessKey = false;
      if (ownprofiledata.hasOwnProperty('oneweekfreeplan')) {
        oneWeekPlanSuccessKey = true;
      }
      //  ownprofiledata.hasOwnProperty('oneweekfreeplan') ? true : false;
      // let oneweekPlanScreeningKey = false;
      // if(ownprofiledata.hasOwnProperty('oneweekscreeningwindow')){
      //   oneweekPlanScreeningKey = true;
      // }

      //  console.log(oneweekPlanScreeningKey);


      //  let isquickPlan = ownprofiledata.plansNpacks.quick;
      // let isExpressPlan = ownprofiledata.plansNpacks.express;
      // let isAssistplan = ownprofiledata.plansNpacks.assisted;
      const getplantimestamp = ownprofiledata.plansNpacks.plantimestamp;

      // One Week Plan Offer For Selfie Video..........

      if (oneWeekPlanSuccessKey && ownprofiledata.oneweekfreeplan != null) {
        // console.log("enter if condition");
        const oneweekplanvalue = ownprofiledata.oneweekfreeplan;
        if (oneweekplanvalue > currentDate && oneweekplanvalue === getplantimestamp) {
          // console.log("check current timestamp");
          if (!isModelSuccessShow && this.forOneWeekSuccessModel === false) {
            //  console.log("success plan dailog");
            return "successShowModel"
          }
        }
      }
      //   else {

      //    if(oneweekPlanScreeningKey && ownprofiledata.oneweekscreeningwindow === true){

      //       if(isAssistplan === false && isExpressPlan === false && isquickPlan === false){

      //         if(ownprofiledata.videodata.length <= 0 && !isModelShow && this.forOneWeekShowModel === false){

      //             return "oneweekfreemodel"
      //         } else if(ownprofiledata.videodata.length > 0 && ownprofiledata.videodata[0].declined && !isModelShow && this.forOneWeekShowModel === false){

      //             return "oneweekfreemodel"
      //         }
      //       }
      //   } 
      // }


      // Free video model code end.................
    }
  }


  //one week free banner plan i explore
  oneWeekFreeBannerPlan() {
    const ownprofiledata = this.ownProfileData;
    if (ownprofiledata) {

      const currentDate = new Date().getTime();


      let oneWeekPlanSuccessKey = false;
      if (ownprofiledata.hasOwnProperty('oneweekfreeplan')) {
        oneWeekPlanSuccessKey = true;
      }


      const getplantimestamp = ownprofiledata.plansNpacks.plantimestamp;


      if (oneWeekPlanSuccessKey && ownprofiledata.oneweekfreeplan != null) {
        // console.log("enter if condition");
        const oneweekplanvalue = ownprofiledata.oneweekfreeplan;
        if (oneweekplanvalue > currentDate && oneweekplanvalue === getplantimestamp) {

          return "showOneWeekBanner"

        }
      }

    }
  }


  // Add timer with countdown .............

  setFreeTimerData(remainTimestamps) {
    let remainingdays: any = {};
    // this.planCountDownTimer = setInterval(function() {
    // console.log("for set time interval");

    var countDownDate = remainTimestamps;
    var now = new Date().getTime();
    var timeleft = countDownDate - now;

    // Calculating the days, hours, minutes and seconds left
    remainingdays.day = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    remainingdays.hour = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    remainingdays.minute = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    remainingdays.second = Math.floor((timeleft % (1000 * 60)) / 1000);


    return remainingdays;


  }





  //Code End here..............


  // showOneWeekPlanBanner(ownprofiledata){
  //   let oneweekPlanScreeningKey = ownprofiledata.hasOwnProperty('oneweekscreeningwindow');
  //   if(oneweekPlanScreeningKey && ownprofiledata.oneweekscreeningwindow === true){

  //      if(ownprofiledata.plansNpacks.assisted === false && ownprofiledata.plansNpacks.express === false && ownprofiledata.plansNpacks.quick === false){

  //        if(ownprofiledata.videodata.length <= 0 ){

  //            return true;
  //        } else if(ownprofiledata.videodata.length > 0 && ownprofiledata.videodata[0].declined){

  //          return true;
  //        }
  //      }
  //  } 
  // }

  // show one week free plan banner in screening

  // showOneWeekPlanBannerScreening(ownprofiledata){
  //   let oneweekPlanOnboardKey = ownprofiledata.hasOwnProperty('oneweekfreeonboard');
  //   if(oneweekPlanOnboardKey && ownprofiledata.oneweekfreeonboard === true){

  //        if(ownprofiledata.videodata.length <= 0 ){

  //            return true;
  //        } else if(ownprofiledata.videodata.length > 0 && ownprofiledata.videodata[0].declined){

  //          return true;
  //        }

  //  } 
  // }






  getInternetConnectionStatus() {
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );
    this.checkInternetSpeed();
  }

  checkInternetSpeed() {
    const connection = navigator['connection'] || navigator['mozConnection'] || navigator['webkitConnection'];
    if (connection) {
      connection.addEventListener('change', () => this.evaluateConnection(connection));
      // this.evaluateConnection(connection);
    }
  }

  evaluateConnection(connection: any) {
    const speed = connection.downlink;
    const effectiveType = connection.effectiveType;
    if (speed < 1 || effectiveType === '2g' || effectiveType === '3g') {
      const snackBar = this.snackbar.open('Your internet connection is slow.', 'OKAY', {
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      });
    }
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  getOperaBrowserStatus() {
    return (navigator.userAgent.indexOf('OPR/') > -1) && (navigator.userAgent.indexOf('Mobile') > -1) && (navigator.userAgent.indexOf('Presto/') < 0);
  }

}
