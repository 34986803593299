import { Injectable } from '@angular/core';
import { CanLoad, Router, CanActivate } from '@angular/router';
import { LocalStorageService } from './local-storage.service';
import { SharedService } from './shared.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements CanLoad {

    constructor(private localservice: LocalStorageService, private router: Router, private sharedservice:SharedService) { }

    canLoad() {
        try {
            const localdata = this.localservice.getFromLocal('user');
            if (localdata) {
                if (localdata.issignuppending) {
                    this.router.navigate(['/register']);
                    return false;
                } else {
                    return true;
                }
            } else {
                this.router.navigate(['/login']);
                return false;
            }
        } catch (err) {
            console.log('error in fetching user local data ' + err);
            return false;
        }
    }

    isRegisteredUser() {
        const localToeknObj = this.localservice.getFromLocal('user');
        try {
            if (localToeknObj.issignuppending) {
                return false;
            } else {
                return true;
            }
        } catch (err) {
            console.log('error in getting local data ' + err);
        }
    }

    logOut() {

        // const confirmDialog = this.md.open(ConfirmationDialogComponent, { autoFocus: false });
        // confirmDialog.afterClosed().subscribe(res => {
        //   if (res) {

        //   } else {
        //     console.log(false);
        //   }
        // });

    
        //this.localservice.removeToken('user');
        // this.localservice.removeToken('communitydata');
        // this.localservice.removeToken('countrycodedata');
        // this.localservice.removeToken('eduq1data');
        // this.localservice.removeToken('langdata');
        // this.localservice.removeToken('ethinicdata');
        // this.localservice.removeToken('currentPrefsData');
        // let notShowAgain = false;
        // if(this.localservice.getFromLocal('NotShowAgain')){
        //    notShowAgain = true;
        // }
   
        // if(notShowAgain){
        //  this.localservice.setToLocal('NotShowAgain', 'true');
        // }
        localStorage.clear();
        //this.router.navigate(['/login']);
        this.sharedservice.resetAnalyticsEvent();
        window.location.reload();
    
    }
}


@Injectable({
    providedIn: 'root'
})

export class CreateProfileService implements CanLoad {
    constructor(private router: Router, private localservice: LocalStorageService) { }
    canLoad() {
        try {
            const localdata = this.localservice.getFromLocal('user');
            if (localdata) {
                // if (localdata.issignuppending) {
                //   return true;
                // } else {
                //   if (localdata.showscreeningpage) {
                //     this.router.navigate(['/user/profile-screening/pending']);
                //   } else {
                //     this.router.navigate(['/user/browse']);
                //   }
                //   return true;
                // }
                return true;
            } else {
                this.router.navigate(['/login']);
                return false;
            }
        } catch (err) {
            console.log('error in fetching user local data ' + err);
            return false;
        }
    }
}


@Injectable({
    providedIn: 'root'
})


export class StaticModuleService implements CanLoad {
    constructor(private router: Router, private localservice: LocalStorageService) { }
    canLoad() {
        try {
            // console.log();
            const localdata = this.localservice.getFromLocal('user');
            if (window.location.pathname === '/assisted-connect-verify-mobile-number' || window.location.pathname === '/assisted-connect' || window.location.pathname === '/assisted-connect-set-account-password') {
                return true;
            } else if (localdata) {
                if( window.location.pathname === '/logout'){
                    return true; 
                }
                if (window.location.pathname === '/verify') {
                    try {
                        const verifyUrl = window.location.search.substr(1).split('&');
                        const verifyId = verifyUrl[0].split('id=')[1];
                        if (localdata.issignuppending) {
                            this.router.navigate(['/register/verify'], { queryParams: { id: verifyId, utm_source: (verifyUrl[1].split('utm_source=')[1]).toString(), utm_medium: (verifyUrl[2].split('utm_medium=')[1]).toString(), utm_campaign: (verifyUrl[3].split('utm_campaign=')[1]).toString() } });
                            return false;
                        } else {
                            this.router.navigate(['/user/verify'], { queryParams: { id: verifyId, utm_source: (verifyUrl[1].split('utm_source=')[1]).toString(), utm_medium: (verifyUrl[2].split('utm_medium=')[1]).toString(), utm_campaign: (verifyUrl[3].split('utm_campaign=')[1]).toString() } });
                            return false;
                        }
                    } catch (err) {
                        console.log('error in fetching  data ' + err);
                    }
                } else if(window.location.pathname === '/contact-us'){
                    if (localdata.issignuppending) {
                        this.router.navigate(['/register/contact-us']);
                        return false;
                    }
                    else {  
                        if (localdata.showscreeningpage) {
                            this.router.navigate(['/user/profile-screening/contact-us']);
                        }else {
                            this.router.navigate(['/user/contact-us']);
                        }
                        return false;
                    }
                }
                else {
                    if (localdata.issignuppending) {
                        this.router.navigate(['/register']);
                        return false;
                    } else {
                        //  console.log('postlogin service');
                        this.router.navigate(['/user']);
                        return false;
                    }
                }

                // if (localdata.issignuppending) {
                //   this.router.navigate(['/register']);
                //   return false;
                // } else {
                //   if (localdata.showscreeningpage) {
                //     this.router.navigate(['/user/profile-screening/pending']);
                //   } else {
                //     this.router.navigate(['/user/browse']);
                //   }
                //   return false;
                // }

            } else {
                return true;
            }
            // if (window.location.pathname === '/verify') {
            //   return true;
            // } else if ()
        } catch (err) {
            console.log('error in fetching user local data ' + err);
            return false;
        }
    }
}

// for screening
@Injectable({
    providedIn: 'root'
})

export class ProfileScreeningGuard implements CanActivate {
    constructor(private router: Router, private localservice: LocalStorageService, private sharedservice: SharedService) { }
    canActivate() {
        try {
            const localdata = this.localservice.getFromLocal('user');
            if (!localdata.showscreeningpage) {
                this.router.navigate(['/user/browse']);
                return false;
            } else {
                return true;
            }
        } catch (err) {
            return false;
        }
    }
}

// for post login after screening
@Injectable({
    providedIn: 'root'
})

export class PostLoginGuardForProfileScreening implements CanActivate {
    constructor(private router: Router, private localservice: LocalStorageService, private sharedservice: SharedService) { }
    canActivate() {
        try {
            const localdata = this.localservice.getFromLocal('user');
            if (localdata.showscreeningpage) {
                this.router.navigate(['/user/profile-screening/pending']);
                return false;
            } else {
                return true;
            }
        } catch (err) {
            return false;
        }
    }
}

@Injectable()

export class GuardForOtpVerifyPage implements CanActivate {
    constructor(private router: Router, private localservice: LocalStorageService, private sharedservice: SharedService) { }
    canActivate() {
        try {
            const localdata = this.localservice.getFromLocal('user');
            if (localdata && localdata.assistedSignUp) {
                return true;
            } else {
                this.router.navigate(['/assisted-connect']);
                return false;
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    }
}
